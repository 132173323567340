import { BoardCommonControllerService } from '@/__generated__/CommonApi';
import { CorporateControllerService, CsrVo } from '@/__generated__/FrontApi';
import Obj1 from '@/assets/img/icons/ico_philanthropie_obj1@2x.png';
import Obj2 from '@/assets/img/icons/ico_philanthropie_obj2@2x.png';
import Obj3 from '@/assets/img/icons/ico_philanthropie_obj3@2x.png';
import Obj4 from '@/assets/img/icons/ico_philanthropie_obj4@2x.png';
import Processes1 from '@/assets/img/icons/ico_philanthropie_processes1@2x.png';
import Processes2 from '@/assets/img/icons/ico_philanthropie_processes2@2x.png';
import Processes3 from '@/assets/img/icons/ico_philanthropie_processes3@2x.png';
import Processes4 from '@/assets/img/icons/ico_philanthropie_processes4@2x.png';
import { Article } from '@/components/Article/types';
import Button from '@/components/Button';
import ThumbnailCardSample from '@/components/Card/ThumbnailCardSample';
import Container from '@/components/Container';
import ContributionLogo, {
  CategoryData,
  PartnerData,
} from '@/components/ContributionLogo';
import PageComponent from '@/components/PageComponent';
import ProcessList from '@/components/ProcessList';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { BOARD_KIND_PARTNER } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const processes = [
  {
    id: 1,
    title: `기업·기관 기부`,
    img: Obj1,
    titleEn: `Corporate Grants`,
    desc: [
      {
        dec: `기업·기관의 이름으로 유니세프에 기금 후원`,
      },
    ],
  },
  {
    id: 2,
    title: `임직원 기부`,
    img: Obj2,
    titleEn: `Employee Giving`,
    desc: [
      {
        dec: `기업 임직원 여러분의 끝전기부 또는 일시·정기 후원금을 모아서 후원`,
      },
    ],
  },
  {
    id: 3,
    title: `매칭 기프트`,
    img: Obj3,
    titleEn: `Matching Gift`,
    desc: [
      {
        dec: `임직원 후원금 또는 고객 후원금에 매칭하여 기업이 함께 후원`,
      },
    ],
  },
  {
    id: 4,
    title: `긴급구호`,
    img: Obj4,
    titleEn: `Emergency`,
    desc: [
      {
        dec: `내전, 자연재해, 질병 창궐과 같은 긴급구호 상황 시 기업·단체에서 직접 긴급구호 기금을 후원하거나 기업·단체의 채널을 활용해 모금된 기금 후원`,
      },
    ],
  },
];

const processes2 = [
  {
    id: 1,
    title: `1. 문의 및 상담`,
    img: Processes1,
    desc: [
      {
        dec: `웹사이트`,
        link: `/involve/corporate-and-organization/social-contribution#sectionInquiry`,
      },
      {
        dec: `전화 / 이메일`,
      },
    ],
  },
  {
    id: 2,
    title: `2. 제안서 교류`,
    img: Processes2,
    desc: [
      {
        dec: `기업의 니즈를 반영한 가장 적합한 \n사회공헌 활동을 제안`,
      },
    ],
  },
  {
    id: 3,
    title: `3. 기부금 영수증 발급`,
    img: Processes3,
    desc: [
      {
        dec: `웹사이트 문의`,
        // link: `/involve/corporate-and-organization/social-contribution#sectionInquiry`,
      },
      {
        dec: `전화 / 이메일`,
      },
    ],
  },
  {
    id: 4,
    title: `4. 후원 리포트 제공`,
    img: Processes4,
    desc: [
      {
        dec: `지속적인 사업 모니터링 후\n후원 결과 리포트를 제공`,
      },
    ],
  },
];

const SectionSort = styled(Section)`
  ${breakpoint(`tablet`)} {
    .process-ol {
      & > li {
        width: 100% !important;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const SectionProcess = styled(Section)`
  background: #f8f9fa;

  .process-ol {
    dd {
      li {
        margin-bottom: 0;
        line-height: 2;
      }
    }
  }
`;

const SectionPartner = styled(Section)`
  .SectionHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${Tit} {
      line-height: 56px;
    }
  }

  .TabContainer {
    margin-top: 64px;
  }

  .list-item {
    width: 20%;
  }

  ${breakpoint(`tablet`)} {
    padding-top: 120px !important;
    .list-item {
      width: 25%;
    }
  }

  ${breakpoint(`mobile`)} {
    h2 {
      margin-bottom: 72px;
    }
    .list-item {
      width: 50%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

// 한 페이지당 보여줄 아이템 수
const pagePerCount = 8;

const Contribution: React.FC<PageProps> = ({ location }) => {
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [partners, setPartners] = useState<PartnerData[]>([]);

  const loadCategories = useCallback(async () => {
    try {
      const {
        data: organizationCategory,
      } = await CorporateControllerService.getCsrCategoryListUsingGet();
      setCategories(
        organizationCategory.map((category: CsrVo) => ({
          id: category.indexNumber,
          categoryCodeName: category.csrCate1,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadPartners = useCallback(async () => {
    try {
      const {
        data: organizationLogo,
      } = await CorporateControllerService.getCsrLogoListUsingGet();
      setPartners(
        organizationLogo.map((logo: CsrVo) => ({
          id: logo.indexNumber,
          indexNumber: logo.indexNumber,
          title: logo.csrName,
          category1: logo.csrCate1,
          category2: logo.csrCate2,
          url: logo.csrSite,
          year: logo.csrYear,
          desc: logo.csrDesc,
          imgPath: logo.logoAttGrpNo,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 현재 페이지
  const [page, setPage] = useState(1);
  // 전체 글 수 (현재 조건기준)
  const [total, setTotal] = useState(0);
  const [newsLists, setNewsLists] = useState<Article[]>([]);

  const hasMore = useMemo(() => total > pagePerCount * page, [page, total]);

  const loadItem = useCallback(
    () =>
      BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_PARTNER],
        pagePerCount,
        page,
      })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .then(({ data, totalCount }) => {
          setNewsLists((prevEvents) => [...prevEvents, ...data]);
          setTotal(totalCount);
        })
        .catch((e) => {
          console.error(e);
        }),
    [page],
  );

  useEffect(() => {
    loadItem();
    loadCategories();
    loadPartners();
  }, [loadCategories, loadItem, loadPartners]);

  // 다음 페이지 로드
  const loadNextPage = () => {
    setPage(page + 1);
  };

  return (
    <LayoutWithTitle
      location={location}
      title="필란트로피"
      description="for every child, partnerships"
    >
      <SectionSort className="by-sub-main-layout">
        <Container>
          <SectionHeader>
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  유니세프 후원을 통해
                  <br />더 많은 어린이를 지켜주세요
                </PageComponent>
              </Tit>
            </h2>
          </SectionHeader>
          <ProcessList
            itemData={processes}
            classNmae="process-list"
            type="type-2"
          />
        </Container>
      </SectionSort>

      <SectionProcess className="by-sub-main-layout">
        <Container>
          <SectionHeader>
            <h2>
              <Tit size="s1">후원 및 제휴 프로세스</Tit>
            </h2>
          </SectionHeader>
          <ProcessList itemData={processes2} arrow className="ProcessList" />
        </Container>
      </SectionProcess>

      <SectionPartner className="by-sub-main-layout">
        <Container
          css={`
            position: relative;
          `}
        >
          <SectionHeader
            className="SectionHeader"
            css={`
              ${breakpoint(640)} {
                padding-bottom: 0;
              }
            `}
          >
            <h2
              css={`
                ${breakpoint(640)} {
                  margin-bottom: 0;
                }
              `}
            >
              <Tit size="s1">함께하는 기업·기관</Tit>
            </h2>
          </SectionHeader>
          <ContributionLogo partners={partners} categories={categories} />
        </Container>
      </SectionPartner>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader>
            <h2>
              <Tit size="s1">필란트로피 소식</Tit>
            </h2>
          </SectionHeader>
          <ThumbnailCardSample newsLists={newsLists} />
          {hasMore && (
            <ButtonContainer>
              <Button
                outline
                width={352}
                ico="moreArrow"
                onClick={() => {
                  loadNextPage();
                }}
              >
                <span className="ico">더 보기</span>
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Contribution;
